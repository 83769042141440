import { useEffect, useState } from "react";
import { requestApi } from "../../../../../utils/request"
import { getUserConnected } from "../../../../../utils/user";

export default function EditCell({ cellId }) {

    const [cell, setCell] = useState([]);

    const loadCell = async () => {
        const res = await requestApi('/admin/getContentById', 'post', {
            adminId: getUserConnected(),
            cellId: cellId,
        });
        if (res.data.success) {
            setCell(res.data.data);
        }
    }

    useEffect(() => {
        loadCell();
    }, []);


    switch (cell.type) {
        case 'img':
            return (<EditImage cell={cell} />);
        case 'p':
            return (<EditParagraph cell={cell} />)
        case 'h1':
            return (<EditTitle cell={cell} />);
        case 'a':
            return (<EditLink cell={cell} />)
    }
}

function EditParagraph({ cell }) {

    const [fs, setFs] = useState(cell.font_size);
    const [orderIndex, setOrderrIndex] = useState(cell.order_index);
    const [align, setAlign] = useState(cell.text_align);
    const [color, setColor] = useState(cell.text_color);

    const updatePara = async () => {
        const res = await requestApi('/admin/updatePara', 'post', {
            adminId: getUserConnected(),
            cellId: cell.id,
            font_size: fs,
            order_index: orderIndex,
            text_align: align,
            text_color: color,
        });

        if (res.data.success) {
            window.location.reload();
        } else {
            alert(res.data.msg);
        }
    }

    return (
        <div className="mt-5">
            <h4>
                Editer le paragraphe.
            </h4>
            <input className="form-control" type="number" value={orderIndex} onChange={(e) => { setOrderrIndex(e.target.value) }} />
            <select value={align} onChange={(e) => { setAlign(e.target.value) }} className="form-control mt-3">
                <option value="start">
                    Start
                </option>
                <option value="center">
                    Center
                </option>
                <option value="end">
                    End
                </option>
            </select>
            <input onChange={(e) => { setFs(e.target.value); }} type="number" value={fs} className="form-control mt-3" />
            <input type="color" value={color} onChange={(e) => { setColor(e.target.value); }} className="form-control mt-3" />
            <button onClick={updatePara} className="btn-custom btn w-100 mt-3">Mettre à jour</button>
        </div >
    );
}

function EditTitle({ cell }) {
    const [fs, setFs] = useState(cell.font_size);
    const [orderIndex, setOrderrIndex] = useState(cell.order_index);
    const [align, setAlign] = useState(cell.text_align);
    const [color, setColor] = useState(cell.text_color);

    const updatePara = async () => {
        const res = await requestApi('/admin/updatePara', 'post', {
            adminId: getUserConnected(),
            cellId: cell.id,
            font_size: fs,
            order_index: orderIndex,
            text_align: align,
            text_color: color,
        });

        if (res.data.success) {
            window.location.reload();
        } else {
            alert(res.data.msg);
        }
    }

    return (
        <div className="mt-5">
            <h4>
                Editer le titre.
            </h4>
            <input className="form-control" type="number" value={orderIndex} onChange={(e) => { setOrderrIndex(e.target.value) }} />
            <select value={align} onChange={(e) => { setAlign(e.target.value) }} className="form-control mt-3">
                <option value="start">
                    Start
                </option>
                <option value="center">
                    Center
                </option>
                <option value="end">
                    End
                </option>
            </select>
            <input onChange={(e) => { setFs(e.target.value); }} type="number" value={fs} className="form-control mt-3" />
            <input type="color" value={color} onChange={(e) => { setColor(e.target.value); }} className="form-control mt-3" />
            <button onClick={updatePara} className="btn-custom btn w-100 mt-3">Mettre à jour</button>
        </div >
    );
}

function EditLink({ cell }) {
    const [fs, setFs] = useState(cell.font_size);
    const [orderIndex, setOrderrIndex] = useState(cell.order_index);
    const [align, setAlign] = useState(cell.text_align);
    const [color, setColor] = useState(cell.text_color);
    const [linkTo, setLinkTo] = useState(cell.link_to);

    const updatePara = async () => {
        const res = await requestApi('/admin/updateLink', 'post', {
            adminId: getUserConnected(),
            cellId: cell.id,
            font_size: fs,
            order_index: orderIndex,
            text_align: align,
            text_color: color,
            link_to: linkTo,
        });

        if (res.data.success) {
            window.location.reload();
        } else {
            alert(res.data.msg);
        }
    }

    return (
        <div className="mt-5">
            <h4>
                Editer le Lien.
            </h4>
            <input type="text" className="form-control" value={linkTo} onChange={(e) => { setLinkTo(e.target.value); }} />
            <input className="form-control mt-3" type="number" value={orderIndex} onChange={(e) => { setOrderrIndex(e.target.value) }} />
            <input onChange={(e) => { setFs(e.target.value); }} type="number" value={fs} className="form-control mt-3" />
            <input type="color" value={color} onChange={(e) => { setColor(e.target.value); }} className="form-control mt-3" />
            <button onClick={updatePara} className="btn-custom btn w-100 mt-3">Mettre à jour</button>
        </div >
    );
}

function EditImage({ cell }) {

    const [image, setImage] = useState(null);
    const [order, setOdrer] = useState(cell.order_index);
    const [linkTo, setLinkTo] = useState(cell.link_to);

    const subImage = async (e) => {
        e.preventDefault();
        const res = await requestApi('/admin/updateCellImage', 'post', {
            adminId: getUserConnected(),
            file: image,
            cellId: cell.id,
        });

        if (!res.data.success) {
            alert(res.data.msg);
        } else {
            window.location.reload();
        }
    }

    const updateOrder = async () => {
        const res = await requestApi('/admin/updateLink', 'post', {
            adminId: getUserConnected(),
            cellId: cell.id,
            font_size: 1,
            order_index: order,
            text_align: 'center',
            text_color: '',
            link_to: linkTo,
        });

        if (res.data.success) {
            window.location.reload();
        } else {
            alert(res.data.msg);
        }
    }

    return (
        <div className="mt-5">
            <form onSubmit={subImage}>
                <h4>
                    Editer l'image
                </h4>
                <input onChange={(e) => { setOdrer(e.target.value) }} type="number" placeholder="index" className="form-control" value={order} />
                <input onChange={(e) => { setLinkTo(e.target.value) }} className="mt-3 form-control" value={linkTo} type="text" placeholder="lien vers" />
                <button onClick={updateOrder} type="button" className="btn btn-custom mt-3 w-100">Mettre à jour</button>
                <input required accept=".png, .gif, .jpg, .jpeg" onChange={(e) => { setImage(e.target.files[0]) }} className="form-control mt-3" type="file" />
                <button type="submit" className="btn-custom w-100 btn mt-3">Envoyer l'image</button>
            </form>
        </div>
    );
}