import { useEffect, useState } from "react";
import Footer from "../../Repeats/Footer";
import NavBar from "../../Repeats/NavBar";
import { Form, Button, Container } from 'react-bootstrap';
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";
import { getUserConnected } from "../../../utils/user";

export default function Contact() {

    const [messages, setMessages] = useState([]);

    const loadMessages = async () => {
        const res = await requestApi('/admin/getMessages', 'post', {
            adminId: getUserConnected(),
        });

        setMessages(res.data.data);
    }

    useEffect(() => {
        loadMessages();
    }, []);

    return (
        <>
            <NavBar />
            <div className="container mt-5 mb-5">
                {messages.map((message) => {
                    return (<div className="row">
                        <div className="col col-2">
                            {message.full_name}    
                        </div>
                        <div className="col col-3">
                            {message.email_sender}
                        </div>
                        <div className="col col-2">
                            {message.quest_type}
                        </div>
                        <div className="col col-3">
                            {message.content}
                        </div>
                        <hr />
                    </div>);
                })}
            </div>
            <Footer />
        </>
    )

}