import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../Repeats/NavBar";
import Footer from "../../Repeats/Footer";
import { getUserConnected, userIsConnected } from "../../../utils/user";
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";

export default function EditMember() {
    const { memberId } = useParams();

    const [showModal, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [member, setMember] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [description, setDescription] = useState('');

    const loadMember = async () => {
        const res = await requestApi('/admin/getMemberById', 'post', {
            adminId: getUserConnected(),
            memberId: memberId,
        });

        if (res.data.success) {
            setMember(res.data.data[0]);
        } else {
            setModalContent(res.data.msg);
            setModalShow(true);
        }
    }

    const loadStates = () => {
        setFirstName(member.first_name);
        setLastName(member.last_name);
        setDescription(member.description);
    }

    useEffect(() => {
        loadMember();
    }, []);

    useEffect(() => {
        loadStates();
    }, [member]);

    if (!userIsConnected()) {
        window.location.href = '/';
        return null;
    }

    return (
        <>
            <NavBar />
            <ModalCustom content={modalContent} show={showModal} onHide={() => { setModalShow(false); }} />
            <div className="container">
                <form className="bg-beige p-3 mt-5" style={{ maxWidth: '460px', margin: 'auto' }}>
                    <div className="row">
                        <div className="col col-12 col-lg-6">
                            <input className="form-control" type="text" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                        </div>

                        <div className="col col-12 col-lg-6">
                            <input className="form-control" type="text" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                        </div>
                        <textarea value={description} onChange={(e) => { setDescription(e.target.value); }} className="form-control mt-3" cols="30" rows="10"></textarea>
                        <div className="col col-12 col-lg-6 mt-3">

                        </div>
                    </div>
                </form >
            </div >
            <Footer />
        </>
    )
}