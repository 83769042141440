import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { requestApi } from "../../../utils/request";
import NavBar from "../../Repeats/NavBar";
import Footer from "../../Repeats/Footer";
import { getUserConnected } from "../../../utils/user";

export default function EditEvent() {

    const { eventId } = useParams();
    const [event, setEvent] = useState([]);

    const loadEvent = async () => {
        const res = await requestApi('/admin/getEventById', 'post', {
            eventId: eventId,
            adminId: getUserConnected(),
        });
        if (res.data.success) {
            setEvent(res.data.data);
        } else {
            window.location.href = '/events';
        }
    }

    useEffect(() => {
        loadEvent();
    }, []);

    const updateVisibility = async (e) => {
        const res = await requestApi('/admin/updateEventVisibility', 'post', {
            adminId: getUserConnected(),
            eventId: eventId,
            visibility: e.target.value
        });

        if(res.data.success){
            window.location.reload();
        }
    }

    if (event.length == 0) {
        return null;
    }

    return (
        <>
            <NavBar />
            <div className="container mt-5 mb-5 bg-beige p-3 rounded">
                <div className="row">
                    <div className="col col-10 col-lg-6">
                        <div className="row">
                            <div className="col col-6">
                                <span>Régulier : {event.p_reg}</span>
                                <br />
                                <span>VIP : {event.p_vip}</span>
                            </div>
                            <div className="col col-6">

                            </div>
                        </div>
                    </div>
                    <div className="col col-10 col-lg-6">
                        <span>Total régulier : {event.nb_persons_regular}</span>
                        <br />
                        <span>Total VIP {event.nb_persons_vip}</span>
                    </div>
                    <div className="col col-10 col-lg-6 mt-5">
                        {
                            (event.visible) ?

                                <button className="btn btn-danger" value={0} onClick={updateVisibility}>
                                    Suspendre les réservations
                                </button>
                                :
                                <button className="btn btn-success" value={1} onClick={updateVisibility}>
                                    Rendre publique
                                </button>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}