import Cookies from "js-cookie";

export function getUserConnected() {
    return Cookies.get('idAdmin');
}

export function userIsConnected() {
    return Cookies.get('idAdmin') !== undefined;
}

export function disconnect() {
    Cookies.remove('idAdmin');
    window.location.reload();
}

export function connectUser(idUser) {
    Cookies.set('idAdmin', idUser, { expires: 3 });
}

export function setLang(lang) {
    Cookies.remove('lang');
    Cookies.set('lang', lang);
}

export function getLang() {
    return Cookies.get('lang');
}