import { useParams } from "react-router-dom"
import { url_api } from "../../../../../utils/config"

export default function ImageCell({ element }) {

    const { share, cellId } = useParams();

    console.log(cellId);
    console.log(element.id);

    return (
        <a className={(cellId === element.id) ? 'c-pointor border-select' : 'c-pointor border-normal'} onClick={() => { window.location.href = '/shares/' + share + '/cell/' + element.id }}>
            <img src={url_api + '/assets/pictures/' + element.uri + '.png'} width={'100%'} height={'auto'} style={{ textAlign: 'center', margin: 'auto' }} />
        </a>
    )
}