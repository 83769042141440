import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getLang, getUserConnected } from "../../../../../utils/user";
import { requestApi } from "../../../../../utils/request";

export default function Paragraph({ element }) {
    const [textContent, setTextContent] = useState('');
    const [editedContent, setEditedContent] = useState(''); // Pour stocker le contenu édité
    const { share, cellId } = useParams();

    useEffect(() => {
        switch (getLang()) {
            case 'fr':
                setTextContent(element.text_content_fr);
                break;
            case 'nl':
                setTextContent(element.text_content_nl);
                break;
            case 'en':
                setTextContent(element.text_content_en)
                break;
            default:
                setTextContent('');
        }
        // Assurez-vous que le contenu édité est également mis à jour lorsque le contenu initial change
        setEditedContent(element.text_content_fr);
    }, [element, getLang]);

    // Fonction pour gérer le clic en fonction de la condition
    const handleClick = () => {
        if (cellId !== element.id) {
            window.location.href = '/shares/' + share + '/cell/' + element.id;
        }
    };

    const updateModify = async () => {
        requestApi('/admin/updateTextContent', 'post', {
            adminId: getUserConnected(),
            cellId: cellId,
            textContent: editedContent, // Utiliser le contenu édité
            lang: getLang(),
        });
    }

    return (
        <div>
            <p
                className={(cellId === element.id) ? 'border-select' : 'c-pointor border-normal'}
                onClick={handleClick}
                style={{
                    fontSize: element.font_size + 'em',
                    textAlign: element.text_align,
                    color: element.text_color,
                }}
                contentEditable
                onBlur={(e) => { setEditedContent(e.target.textContent); }}
                dangerouslySetInnerHTML={{ __html: textContent }}
            ></p>
            {(cellId === element.id) ? <button className="btn btn-success" onClick={updateModify}>Valider</button> : ''}
        </div>
    );
}
