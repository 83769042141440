import { userIsConnected } from "../../../utils/user";
import Footer from "../../Repeats/Footer";
import NavBar from "../../Repeats/NavBar";

export default function Home() {

    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    return (
        <>
            <NavBar />
            <div>
                <h1 className="text-center mt-5">
                    Cet espace membre est réservé aux administrateurs.
                </h1>
            </div>
            <Footer />
        </>
    )
}