import NavBar from "../../Repeats/NavBar";
import { getUserConnected, userIsConnected } from "../../../utils/user";
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";
import { useEffect, useState } from "react";
import Footer from "../../Repeats/Footer";

export default function Events() {

    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [date, setDate] = useState('');
    const [eventName, setEventName] = useState('');
    const [nbR, setNbR] = useState(0);
    const [nbV, setNbV] = useState(0);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        loadEvents();
    }, []);

    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await requestApi('/admin/createEvent', 'post', {
            adminId: getUserConnected(),
            date: date,
            nbR: nbR,
            nbV: nbV,
            eventName: eventName,
        });

        if (res.data.success) {
            window.location.href = '/events/' + res.data.data.eventId;
        } else {
            setModalContent(res.data.msg);
            setModalShow(true);
        }
    }

    const loadEvents = async () => {
        const res = await requestApi('/admin/getEvents', 'post', {
            adminId: getUserConnected(),
        });

        if (res.data.success) {
            setEvents(res.data.data);
        }
    }

    return (
        <>
            <ModalCustom onHide={() => { setModalShow(false) }} content={modalContent} show={modalShow} />
            <NavBar />
            <div className="container mt-5 mb-5">
                <form onSubmit={handleSubmit}>
                    <div className="bg-beige p-3" style={{ maxWidth: '460px', margin: 'auto' }}>
                        <input type="datetime-local" className="form-control" value={date} onChange={(e) => { setDate(e.target.value) }} />
                        <label htmlFor="nbR" className="mt-3">Nombre de place régulières</label>
                        <input type="number" id="nbR" className="form-control" value={nbR} onChange={(e) => { setNbR(e.target.value) }} />
                        <label htmlFor="nbV" className="mt-3">Nombre de place VIP</label>
                        <input type="number" id="nbV" className="form-control" value={nbV} onChange={(e) => { setNbV(e.target.value) }} />
                        <label htmlFor="" className="mt-3">Nom de l'évènement</label>
                        <input placeholder="Nom de l'évènement" type="text" max={255} className="form-control" value={eventName} onChange={(e) => { setEventName(e.target.value) }} />
                        <button type="submit" className="btn-sub btn w-100 mt-3">Créer</button>
                    </div>
                </form>
            </div>

            <div className="container mt-5 bg-beige p-3 mb-5 rounded">
                {
                    events.map((event) => {
                        return (
                            <div className="row c-pointor" onClick={() => { window.location.href = "/events/" + event.id }}>
                                <div className="col col-8">
                                    {event.event_name}
                                </div>
                                <div className="col col-4">
                                    {event.date}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <Footer />
        </>
    )
}