import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';  // Importez le composant NavDropdown
import { disconnect, userIsConnected } from '../../utils/user';

export default function NavBar() {
    const { t } = useTranslation();
    const [userConnected, setUserConnected] = useState(false);

    useEffect(() => {
        if (userIsConnected()) {
            setUserConnected(true);
        }
    }, []);

    return (
        <Navbar className='fs-4 bg-beige' expand="lg">
            <div className="container">
                <Navbar.Brand href="/">{t('Accueil')}</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarNav" />
                <Navbar.Collapse id="navbarNav">
                    <Nav className="ms-auto">

                        <NavDropdown title="Édition du contenu" id="services-dropdown">
                            <NavDropdown.Item href="events">Évènements</NavDropdown.Item>
                            <NavDropdown.Item href="shares">Partager</NavDropdown.Item>
                            <NavDropdown.Item href="members">Membres</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link href="contact">Messages</Nav.Link>

                        <Nav.Item className='nav-item'>
                            {userConnected ? <button className='btn btn-sub fs-4' onClick={disconnect}>{t('déconnexion')}</button> : <Nav.Link href='/login'>{t('connexion')}</Nav.Link>}
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}
