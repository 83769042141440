import NavBar from "../../Repeats/NavBar";
import { getUserConnected, userIsConnected } from "../../../utils/user";
import Footer from "../../Repeats/Footer";
import FormNewArticle from "./FormNewArticle";
import { useEffect, useState } from "react";
import { requestApi } from "../../../utils/request";

export default function Shares() {

    const [articles, setArticles] = useState([]);

    const loadArticles = async () => {
        const res = await requestApi('/admin/getAllArticles', 'post', {
            adminId: getUserConnected(),
        });

        if (res.data.success) {
            setArticles(res.data.data);
        }
    }

    useEffect(() => {
        loadArticles();
    }, []);

    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    return (
        <>
            <NavBar />
            <div className="container mb-5">
                <FormNewArticle />

                {articles.map((article) => {
                    return (
                        <div onClick={() => { window.location.href = '/shares/' + article.id }} className={(article.visible) ? 'row publish p-3 c-pointor text-center' : 'row no-publish p-3 c-pointor text-center'}>
                            <div className="col col-6">
                                {article.title}
                            </div>
                            <div className="col col-6">
                                {article.create_at}
                            </div>
                            <hr />
                        </div>
                    )
                })}
            </div>
            <Footer />
        </>
    )
}