import NavBar from "../../Repeats/NavBar";
import { getUserConnected, userIsConnected } from "../../../utils/user";
import { useEffect, useState } from "react";
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";
import Footer from "../../Repeats/Footer";

export default function Membres() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [file, setFile] = useState(null);
    const [role, setRole] = useState('Musicien');
    const [description, setDescription] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [members, setMembers] = useState([]);

    useEffect(() => {
        loadMembers();
    }, [])

    if (!userIsConnected()) {
        window.location.href = '/login';
        return null;
    }

    const handleAdd = async (e) => {
        e.preventDefault();
        let res = await requestApi('/admin/addMember', 'post', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            tel: tel,
            file: file,
            role: role,
            description: description,
            adminId: getUserConnected(),
        })

        if (res.data.success) {
            window.location.reload();
        } else {
            setModalShow(true);
            setModalContent(res.data.msg);
        }
    }

    const loadMembers = async () => {
        const res = await requestApi('/admin/getMembers', 'post', {
            adminId: getUserConnected(),
        });

        if (res.data.success) {
            setMembers(res.data.data);
        }
    }

    return (
        <>
            <NavBar />
            <ModalCustom show={modalShow} onHide={() => { setModalShow(false) }} content={modalContent} />
            <div className="container">
                <form onSubmit={handleAdd} className="form mt-5 bg-beige p-3 rounded" style={{ maxWidth: '460px', margin: 'auto' }}>
                    <div className="container">
                        <div className="row">
                            <h1 className="text-center">
                                Ajouter un nouveau membre
                            </h1>
                            <div className="col col-12 mt-3 col col-lg-6">
                                <input value={firstName} onChange={(e) => { setFirstName(e.target.value) }} placeholder="Prénom" type="text" className="form-control" />
                            </div>
                            <div className=" col col-12 mt-3 col col-lg-6">
                                <input value={lastName} onChange={(e) => { setlastName(e.target.value) }} placeholder="Nom" type="text" className="form-control" />
                            </div>
                            <div className="col col-12 mt-3">
                                <input value={email} onChange={(e) => { setEmail(e.target.value) }} type="email" className="form-control" placeholder="Email" />
                            </div>
                            <div className="col col-12 col-lg-6 mt-3">
                                <input value={tel} onChange={(e) => { setTel(e.target.value) }} className="form-control" type="tel" placeholder="Tel" />
                            </div>
                            <div className="col col-12 col-lg-6 mt-3">
                                <select className="form-control" value={role} onChange={(e) => { setRole(e.target.value) }}>
                                    <option value="Développeur">Développeur</option>
                                    <option value="Chanteur">Chanteur</option>
                                    <option value="Musicien">Musicien</option>
                                    <option value="Régis">Régis</option>
                                    <option value="Chef de projet">Chef de projet</option>
                                </select>
                            </div>
                            <div className="col col-12">
                                <input accept=".png, .jpg, .gif, .jpeg" className="form-control mt-3" type="file" onChange={(e) => { setFile(e.target.files[0]) }} max={1} required />
                            </div>
                            <div className="col col-12">
                                <textarea value={description} onChange={(e) => { setDescription(e.target.value); }} className="form-control mt-3" rows={6}>

                                </textarea>
                            </div>
                            <div className="col col-12">
                                <button type="submit" className="btn btn-primary mt-3">
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5 text-center">
                        {members.map((member) => {
                            return (
                                <a className="link" style={{ textDecoration: 'none' }} href={"/members/" + member.id}>{member.first_name + " " + member.last_name + "(" + member.role + ")"}</a>
                            )
                        })}
                    </div>
                </form>
            </div>
            <Footer />
        </>
    )
}