// App.js
import React, { createRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Home from './components/Pages/Home/Home';
import Contact from './components/Pages/Contact/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/App.css';
import Login from './components/Pages/Connexion/Login/Login';
import Events from './components/Pages/Events/Events';
import Membres from './components/Pages/Members/Members';
import Shares from './components/Pages/Shares/Shares';
import SharesEdit from './components/Pages/Shares/SharesEdit/SharesEdit';
import EditMember from './components/Pages/Members/EditMember';
import EditEvent from './components/Pages/Events/EditEvent';

const routes = [
  { path: '/', name: 'Home', element: <Home />, nodeRef: createRef() },
  { path: '/contact', name: 'Contact', element: <Contact />, nodeRef: createRef() },
  { path: '/login', name: 'Login', element: <Login />, nodeRef: createRef() },
  { path: '/members', name: 'Mebers', element: <Membres />, nodeRef: createRef() },
  { path: '/members/:memberId', name: 'Edit', element: <EditMember />, nodeRef: createRef() },
  { path: '/events', name: 'Events', element: <Events />, nodeRef: createRef() },
  { path: '/events/:eventId', name: 'Events', element: <EditEvent />, nodeRef: createRef() },
  { path: '/shares', name: 'Share', element: <Shares />, nodeRef: createRef() },
  { path: '/shares/:share', name: 'Share', element: <SharesEdit />, nodeRef: createRef() },
  { path: '/shares/:share/cell/:cellId', name: 'Share', element: <SharesEdit />, nodeRef: createRef() },
];

const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const location = useLocation();

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={location.key} classNames="fade" timeout={250} nodeRef={routes.find(route => route.path === location.pathname)?.nodeRef}>
        <div>
          <Routes location={location}>
            {routes.map((route) => (
              <Route key={route.path} exact path={route.path} element={route.element} />
            ))}
          </Routes>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default App;