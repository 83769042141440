import { useState } from "react";
import { requestApi } from "../../../utils/request";
import ModalCustom from "../../Repeats/Modal";
import { getUserConnected } from "../../../utils/user";

export default function FormNewArticle() {

    const [title, setTitle] = useState('');

    const [modalContent, setModalContent] = useState('');
    const [modalShow, setShowModal] = useState(false);

    const handleSub = async (e) => {
        e.preventDefault();
        const res = await requestApi('/admin/addArticle', 'post', {
            adminId: getUserConnected(),
            title: title,
        });
        if (res.data.success) {
            window.location.href = '/shares/' + res.data.data.articleId;
        } else {
            setModalContent(res.data.msg);
            setShowModal(true);
        }
    }

    return (
        <div className="mt-5">
            <ModalCustom show={modalShow} onHide={() => { setShowModal(false) }} content={modalContent} />
            <form onSubmit={handleSub}>
                <div style={{ maxWidth: '460px' }} className="p-3 bg-beige m-auto rounded">
                    <div className="container">
                        <h2>Partager un nouvel article.</h2>
                        <div className="row">
                            <input type="text" value={title} onChange={(e) => { setTitle(e.target.value) }} className="form-control col col-12" />
                            <button className="mt-3 btn-sub btn col col-12">Créer</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}