import { useEffect, useState } from "react";
import { getLang } from "../../../../../utils/user";
import { useParams } from "react-router-dom";
import { requestApi } from "../../../../../utils/request";
import { getUserConnected } from "../../../../../utils/user";

export default function TilteCell({ element }) {

    const [editedContent, setEditedContent] = useState('');
    const { cellId, share } = useParams();
    const lang = getLang();

    useEffect(() => {
        console.log("Langue détectée:", lang);
        switch (lang) {
            case 'fr':
                console.log("Contenu français chargé:", element.text_element_fr);
                setEditedContent(element.text_content_fr);
                break;
            case 'nl':
                console.log("Contenu néerlandais chargé:", element.text_element_nl);
                setEditedContent(element.text_content_nl);
                break;
            case 'en':
                console.log("Contenu anglais chargé:", element.text_element_en);
                setEditedContent(element.text_content_en);
                break;
        }
    }, [element, lang]);

    const handleClick = () => {
        if (cellId !== element.id) {
            window.location.href = '/shares/' + share + '/cell/' + element.id;
        }
    };

    const updateModify = async () => {
        requestApi('/admin/updateTextContent', 'post', {
            adminId: getUserConnected(),
            cellId: cellId,
            textContent: editedContent,
            lang: getLang(),
        });
    }

    return (
        <div>
            <h1
                className={(cellId === element.id) ? 'border-select' : 'c-pointor border-normal'}
                onClick={handleClick}
                style={{
                    fontSize: element.font_size + 'em',
                    textAlign: element.text_align,
                    color: element.text_color,
                }}
                contentEditable
                onBlur={(e) => {
                    const editedText = e.target.textContent;
                    console.log(editedText); // Vérifiez le contenu édité dans la console
                    setEditedContent(editedText);
                }}

            >
                {editedContent}
            </h1>
            {(cellId === element.id) ? <button className="btn btn-success" onClick={updateModify}>Valider</button> : ''}
        </div>
    )

}
