import { useParams } from "react-router-dom";
import Footer from "../../../Repeats/Footer";
import NavBar from "../../../Repeats/NavBar";
import { requestApi } from "../../../../utils/request";
import { getUserConnected, setLang } from "../../../../utils/user";
import { useEffect, useState } from "react";
import Paragraph from "./AllTypes/Paragraph";
import ImageCell from "./AllTypes/ImageCell";
import EditCell from "./Edit/EditCell";
import TilteCell from "./AllTypes/TilteCell";
import LinkCell from "./AllTypes/LinkCell";

export default function SharesEdit() {
    const { share, cellId } = useParams();

    const [article, setArticle] = useState([]);
    const [typeCreate, setTypeCreate] = useState('h1');
    const [components, setComponents] = useState([]);

    const loadShare = async () => {
        const res = await requestApi('/admin/getArticleById', 'post', {
            articleId: share,
            adminId: getUserConnected(),
        });

        if (res.data.success) {
            setArticle(res.data.data);
            setComponents(res.data.data.components);
        } else {
            window.location.href = '/shares';
        }
    }

    const handleCreate = async () => {
        const res = await requestApi('/admin/addArticleContent', 'post', {
            type: typeCreate,
            adminId: getUserConnected(),
            articleId: share,
        });

        if (res.data.success) {
            window.location.href = '/shares/' + share + '/cell/' + res.data.data.content_id;
        } else {
            alert(res.data.msg);
        }
    }

    const changeVisibility = async (visibility) => {
        const res = await requestApi('/admin/publish', 'post', {
            adminId: getUserConnected(),
            articleId: article.id,
            visibility: visibility,
        });

        if (res.data.success) {
            window.location.reload();
        }
    }

    useEffect(() => {
        loadShare();
    }, []);

    return (
        <>
            <NavBar />
            <div className="container mt-5">
                <div className="row">
                    <div className="col col-9">

                    </div>
                    <div className="col col-1">
                        <button className="btn btn-warning" onClick={() => { setLang('fr'); window.location.reload(); }}>
                            FR
                        </button>
                    </div>
                    <div className="col col-1">
                        <button className="btn btn-warning" onClick={() => { setLang('nl'); window.location.reload(); }}>
                            NL
                        </button>
                    </div>
                    <div className="col col-1">
                        <button className="btn btn-warning" onClick={() => { setLang('en'); window.location.reload(); }}>
                            EN
                        </button>
                    </div>
                    <div className="col col-9">

                    </div>
                    <div className="col col-3 mt-5 mb-3">
                        {
                            (!article.visible) ?
                                <button className="btn btn-success w-100" onClick={() => { changeVisibility(true); }}>
                                    Publier
                                </button>
                                :
                                <button className="btn btn-danger w-100" onClick={() => { changeVisibility(false); }}>
                                    Cacher
                                </button>
                        }
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col col-12 col-lg-9">
                        <div className="container">
                            <h1>{article.title}</h1>
                            <hr />
                            {(components == undefined) ? '' : components.map((content) => {
                                switch (content.type) {
                                    case 'p':
                                        return (
                                            <Paragraph key={content.id} element={content} />
                                        );
                                    case 'img':
                                        return (
                                            <ImageCell key={content.id} element={content} />
                                        )
                                    case 'h1':
                                        return (
                                            <TilteCell key={content.id} element={content} />
                                        )
                                    case 'a':
                                        return (
                                            <LinkCell key={content.id} element={content} />
                                        );
                                }
                            })}
                        </div>
                    </div>
                    <div className="col col-12 col-lg-3">
                        <select value={typeCreate} className="form-control" onChange={(e) => { setTypeCreate(e.target.value) }}>
                            <option value={"h1"}>
                                Titre1
                            </option>
                            <option value={"p"}>
                                Paragraphe
                            </option>
                            <option value={"img"}>
                                Image
                            </option>
                            <option value={"a"}>
                                Lien
                            </option>
                        </select>
                        <button onClick={handleCreate} type="submit" className="btn-custom rounded btn w-100 mt-3">Créer</button>
                        <div>
                            {(cellId !== undefined && cellId.length == 36) ? <EditCell cellId={cellId} /> : ''}
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}